<template>
	<modal class="NoxModalAdminLandings" name="NoxModalAdminLandings" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'editLandingsElement'">Редактирование элемента</span>
					<span v-else-if="noxType == 'deleteLandingsElement'">Удаление элемента</span>
				</div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div class="nox_modal_info_landings">
						<div class="nox_modal_info_landings_element">
							<div v-if="noxType == 'editLandingsElement'">
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Код элемента:</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxLandingsElementCode" placeholder="код элемента" autocomplete="off">
										<div v-html="noxAlertLandingsElementCode"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Приоритет элемента:</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxLandingsElementPriority" placeholder="приоритет элемента" autocomplete="off">
										<div v-html="noxAlertLandingsElementPriority"></div>
									</div>
								</div>
								<div class="nox_modal_info_row last">
									<div class="nox_modal_info_row_label">Статус элемента:</div>
									<div class="nox_modal_info_row_value">
										<toggle-button class="nox_toggle_button" :value="toggleValue(noxLandingsElementIsActive)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForLandingsElementIsActive"></toggle-button>
										<div v-html="noxAlertLandingsElementIsActive"></div>
									</div>
								</div>
							</div>
							<div v-else-if="noxType == 'deleteLandingsElement'">Вы действительно хотите удалить элемент?</div>
						</div>
					</div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-if="noxType == 'editLandingsElement'" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
					<button type="button" class="nox_button common red" @click="axios" v-else-if="noxType == 'deleteLandingsElement'" v-html="$store.getters.getLanguageText('1.1', 11)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertLandingsElementCode: '',
			noxAlertLandingsElementPriority: '',
			noxAlertLandingsElementIsActive: '',
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxLandingsElementCode: '',
			noxLandingsElementPriority: '',
			noxLandingsElementIsActive: 0,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxLandingsElementCode = '';
				this.noxLandingsElementPriority = '';
				this.noxLandingsElementIsActive = 0;
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxType == 'editLandingsElement') {

					this.noxLandingsElementCode = this.$parent.noxLandingsElementsData[this.noxIndex].code;
					this.noxLandingsElementPriority = this.$parent.noxLandingsElementsData[this.noxIndex].priority;
					this.noxLandingsElementIsActive = this.$parent.noxLandingsElementsData[this.noxIndex].is_active;
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertLandingsElementCode = '';
				this.noxAlertLandingsElementPriority = '';
				this.noxAlertLandingsElementIsActive = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать id элемента.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный id элемента.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать код элемента.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верный код элемента.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо указать приоритет элемента.'; }
				else if (i == 6) { this.noxTemp = 'Необходимо указать верный приоритет элемента.'; }
				else if (i == 7) { this.noxTemp = 'Необходимо указать статус элемента.'; }
				else if (i == 8) { this.noxTemp = 'Необходимо указать верный статус элемента.'; }
				else if (i == 9) { this.noxTemp = 'Такой приоритет элемента уже есть в системе.'; }
				else if (i == 10) { this.noxTemp = 'Id элемента не найден в системе.'; }
				else              { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (_this.noxType == 'editLandingsElement') {
						if (!_this.noxLandingsElementCode) {
							_this.noxAlertLandingsElementCode = _this.getError(3);
						}
						else if (!_this.$store.state.noxRegex_w.test(_this.noxLandingsElementCode) || _this.noxLandingsElementCode.length > 10) {
							_this.noxAlertLandingsElementCode = _this.getError(4);
						}
						if (!_this.noxLandingsElementPriority) {
							_this.noxAlertLandingsElementPriority = _this.getError(5);
						}
						else if (!_this.$store.state.noxRegex_d.test(_this.noxLandingsElementPriority) || _this.noxLandingsElementPriority < 1) {
							_this.noxAlertLandingsElementPriority = _this.getError(6);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/landings/elements';
							config.data = { id: _this.noxId, code: _this.noxLandingsElementCode, priority: _this.noxLandingsElementPriority, is_active: _this.noxLandingsElementIsActive };
							config.method = 'patch';
						}
					}
					else if (_this.noxType == 'deleteLandingsElement') {
						config.url = '/v2/admin/account/landings/elements';
						config.data = { id: _this.noxId };
						config.method = 'delete';
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.noxType == 'editLandingsElement') {
									_this.$parent.noxLandingsElementsData.splice(_this.noxIndex, 1, data.data);
								}
								else if (_this.noxType == 'deleteLandingsElement') {
									_this.$parent.noxLandingsElementsData.splice(_this.noxIndex, 1);
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['editLandingsElement', 'deleteLandingsElement'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'CODE_IS_EMPTY') { _this.noxAlertLandingsElementCode = _this.getError(3); }
										else if (data.response.data.error == 'CODE_NOT_VALID') { _this.noxAlertLandingsElementCode = _this.getError(4); }
										else if (data.response.data.error == 'PRIORITY_IS_EMPTY') { _this.noxAlertLandingsElementPriority = _this.getError(5); }
										else if (data.response.data.error == 'PRIORITY_NOT_VALID') { _this.noxAlertLandingsElementPriority = _this.getError(6); }
										else if (data.response.data.error == 'IS_ACTIVE_IS_EMPTY') { _this.noxAlertLandingsElementIsActive = _this.getError(7); }
										else if (data.response.data.error == 'IS_ACTIVE_NOT_VALID') { _this.noxAlertLandingsElementIsActive = _this.getError(8); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (['editLandingsElement', 'deleteLandingsElement'].includes(_this.noxType)) {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(10); }
									}
								}
								else if (data.response.status == 409) {
									if (['editLandingsElement', 'deleteLandingsElement'].includes(_this.noxType)) {
										if (data.response.data.error == 'PRIORITY_DUPLICATE') { _this.noxAlertLandingsElementPriority = _this.getError(9); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChangeForLandingsElementIsActive: function(event) {
				this.noxLandingsElementIsActive = Number(event.value);
			},
			close: function() {
				this.$modal.hide('NoxModalAdminLandings');
			}
		}
	}
</script>
